<template>
  <el-dialog :title="type === 'add' ? '添加图集' : '编辑图集'" v-model="visible" width="650px" @close="handleClose"
    custom-class="dialog" :close-on-click-modal="false">
    <el-form :model="ruleForm" :rules="rules" ref="formRef" label-width="100px" class="good-form" v-loading="loading">
      <el-form-item prop="supportType">
        <el-radio-group v-model="ruleForm.supportType">
          <el-radio-button @click="editState(item.value)" v-for="item in SUPPORT_TYPE" :key="item.value" :label="item.value">
            {{ item.label }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <template v-if="ruleForm.supportType === '3' ">
        <el-form-item label="组件品牌" prop="comBrand">
          <el-select v-model="ruleForm.comBrand" placeholder="请选择">
            <el-option v-for="option in COM_BRAND" :key="option.value" :label="option.label" :value="option.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组件型号" prop="comModel">
          <el-select v-model="ruleForm.comModel" placeholder="请选择">
            <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否抬高" prop="trueOrFalseRaise">
          <el-select v-model="ruleForm.trueOrFalseRaise" placeholder="请选择">
            <el-option v-for="option in RAISE_TYPES" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="安装方式" prop="installationMethod">
          <el-select v-model="ruleForm.installationMethod" placeholder="请选择">
            <el-option v-for="option in INSTALL_METHOD_TYPES" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="风压等级" prop="windPressureLevel">
          <el-select v-model="ruleForm.windPressureLevel" placeholder="请选择">
            <el-option v-for="option in WIND_PRESSURE_LEVEL_TYPES" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
<!--        <el-form-item v-if="ruleForm.rooftype !== 1" label="后拉方式" prop="trueOrFalseRaise">-->
<!--          <el-select v-model="ruleForm.trueOrFalseRaise" placeholder="请选择">-->
<!--            <el-option v-for="option in PULL_BACK_TYPES" :key="option.value" :label="option.label"-->
<!--                       :value="option.value" />-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="南北排数" prop="northSouthNumber">
          <el-select v-model="ruleForm.northSouthNumber" placeholder="请选择">
            <el-option v-for="option in NORTH_SOUTH_TYPE" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
      </template>
      <template v-if="ruleForm.supportType === '3'">
        <el-form-item label="安装倾角" prop="installationInclination">
          <el-input v-model="ruleForm.installationInclination" placeholder="请输入组件阵列安装倾角" />
        </el-form-item>
        <el-form-item label="组件高度" prop="comHeight">
          <el-input v-model="ruleForm.comHeight" placeholder="请输入组件阵列最南侧组件高度" />
        </el-form-item>
        <el-form-item label="立柱高度" prop="columnHeight">
          <el-input v-model="ruleForm.columnHeight" placeholder="请输入最南侧立柱高度" />
        </el-form-item>
        <el-form-item label="斜梁长度" prop="inclinedHeight">
          <el-input v-model="ruleForm.inclinedHeight" placeholder="请输入斜梁长度" />
        </el-form-item>
        <el-form-item label="斜梁位置" prop="inclinedBeamLocation">
          <el-input v-model="ruleForm.inclinedBeamLocation" placeholder="请输入组件南侧距斜梁南侧距离" />
        </el-form-item>
        <el-form-item label="立柱位置" prop="columnPosition">
          <el-input v-model="ruleForm.columnPosition" placeholder="多个立柱用逗号','隔开" />
        </el-form-item>
        <el-form-item label="屋顶宽度" prop="widthMin">
          <el-input v-model.number="ruleForm.widthMin" placeholder="请输入屋顶最小可利用宽度" />
        </el-form-item>
        <el-form-item label="对应图集" prop="atlas">
          <el-upload action="http://47.97.178.209/api/File/UploadFile" :limit="1" :on-success="handleAtlasSuccess" :auto-upload="true"
            ref="atlasRef" :show-file-list="false">
            <template #trigger>
              <el-button type="primary">请选择文件</el-button>
            </template>
          </el-upload>
          <el-button v-if="!!ruleForm.atlas" type="primary" @click="handleDownload(ruleForm.atlas)" :text="true">查看文件
          </el-button>
        </el-form-item>
        <el-form-item label="对应BOM" prop="bom">
          <el-upload action="http://47.97.178.209/api/File/UploadFile" :limit="1" :on-success="handleBomSuccess" :auto-upload="true"
            ref="bomRef" :show-file-list="false">
            <template #trigger>
              <el-button type="primary">请选择文件</el-button>
            </template>
          </el-upload>
          <el-button v-if="!!ruleForm.bom" type="primary" @click="handleDownload(ruleForm.bom)" :text="true">查看文件
          </el-button>
        </el-form-item>
      </template>
      <template v-if="ruleForm.supportType === '2' || ruleForm.supportType === '1'">
        <el-form-item label="组件品牌" prop="comBrand">
          <el-select v-model="ruleForm.comBrand" placeholder="请选择">
            <el-option v-for="option in COM_BRAND" :key="option.value" :label="option.label" :value="option.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否抬高" prop="trueOrFalseRaise">
          <el-select v-model="ruleForm.trueOrFalseRaise" placeholder="请选择">
            <el-option v-for="option in RAISE_TYPES" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="组件型号" prop="comModel">
          <el-select v-model="ruleForm.comModel" placeholder="请选择">
            <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装方式" prop="installationMethod">
          <el-select v-model.number="ruleForm.installationMethod" placeholder="请选择">
            <el-option v-for="option in INSTALLATION" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="风压等级" prop="windPressureLevel">
          <el-select v-model="ruleForm.windPressureLevel" placeholder="请选择">
            <el-option v-for="option in WIND_PRESSURE_LEVEL_TYPES" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="南北排数" prop="northSouthNumber">
          <el-select v-model="ruleForm.northSouthNumber" placeholder="请选择">
            <el-option v-for="option in NORTH_SOUTH_TYPE" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="支架类型" prop="supportType">
          <el-select v-model="ruleForm.supportType" placeholder="请选择">
            <el-option v-for="option in HOLDER_TYPE" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="配N图集" prop="nGrahics" v-if="ruleForm.supportType === '2'">
          <el-select v-model="ruleForm.nGrahics" placeholder="请选择">
            <el-option v-for="option in N_ATLAS" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="安装倾角" prop="installationInclination">
          <el-input v-model.number="ruleForm.installationInclination" placeholder="请输入组件阵列安装倾角" />
        </el-form-item>
        <el-form-item label="立柱高度" prop="columnHeight">
          <el-input v-model="ruleForm.columnHeight" placeholder="请输入最南侧立柱高度" />
        </el-form-item>
        <el-form-item label="斜梁长度" prop="inclinedHeight">
          <el-input v-model="ruleForm.inclinedHeight" placeholder="请输入斜梁长度" />
        </el-form-item>
        <el-form-item label="斜梁位置" prop="inclinedBeamLocation">
          <el-input v-model="ruleForm.inclinedBeamLocation" placeholder="请输入组件南侧距斜梁南侧距离" />
        </el-form-item>
        <el-form-item label="立柱位置" prop="columnPosition">
          <el-input v-model="ruleForm.columnPosition" placeholder="多个立柱用逗号','隔开" />
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请选择">
            <el-option v-for="option in WIND_AREA" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="对应图集" prop="atlas">
          <el-upload action="http://47.97.178.209/api/File/UploadFile" :limit="1" :on-success="handleAtlasSuccess" :auto-upload="true"
                     ref="atlasRef" :show-file-list="false">
            <template #trigger>
              <el-button type="primary">请选择文件</el-button>
            </template>
          </el-upload>
          <el-button v-if="!!ruleForm.atlas" type="primary" @click="handleDownload(ruleForm.atlas)" :text="true">查看文件
          </el-button>
        </el-form-item>
        <el-form-item label="对应BOM" prop="bom">
          <el-upload action="http://47.97.178.209/api/File/UploadFile" :limit="1" :on-success="handleBomSuccess" :auto-upload="true"
                     ref="bomRef" :show-file-list="false">
            <template #trigger>
              <el-button type="primary">请选择文件</el-button>
            </template>
          </el-upload>
          <el-button v-if="!!ruleForm.bom" type="primary" @click="handleDownload(ruleForm.bom)" :text="true">查看文件
          </el-button>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { SUPPORT_TYPE, N_ATLAS, HOLDER_TYPE, INSTALLATION, PROJECT_TYPE, ROOF_TYPES, COM_MODAL_TYPE, RAISE_TYPES, PULL_BACK_TYPES, INSTALL_METHOD_TYPES, WIND_PRESSURE_LEVEL_TYPES, NORTH_SOUTH_TYPE, COM_BRAND, WIND_AREA} from '../const';
export default {
  name: 'DialogAddHotGood',
  props: {
    type: String
  },
  setup() {

    const store = useStore();
    const formRef = ref(null);
    const atlasRef = ref(null);
    const bomRef = ref(null);
    const initialValue = {
      rooftype: 1,
      comModel: '',
      trueOrFalseRaise: null,
      installationMethod: undefined,
      windPressureLevel: undefined,
      northSouthNumber: '',
      installationInclination: undefined,
      comHeight: undefined,
      columnHeight: undefined,
      inclinedHeight: undefined,
      inclinedBeamLocation: undefined,
      columnPosition: '',
      widthMin: undefined,
      comBrand:'',
      atlas: '',
      bom: '',
      supportType: '', //支架类型
      projectType: '', //项目类型
      nGrahics: '', //配n图集  接口暂时没字段
      region: '' //地区
    }
    // const initialValueYi = {
    //   rooftype: 1,
    //   comModel: '',
    //   trueOrFalseRaise: 1,
    //   installationMethod: undefined,
    //   windPressureLevel: undefined,
    //   northSouthNumber: '',
    //   installationInclination: undefined,
    //   comHeight: undefined,
    //   columnHeight: undefined,
    //   inclinedHeight: undefined,
    //   inclinedBeamLocation: undefined,
    //   columnPosition: '',
    //   widthMin: undefined,
    //   comBrand:'',
    //   atlas: '',
    //   bom: '',
    //   supportType: '', //支架类型
    //   projectType: '', //项目类型
    //   peiN: '', //配n图集  接口暂时没字段
    //   nGrahics: ''
    // }
    const state = reactive({
      visible: false,
      id: '',
      ruleForm: { ...initialValue },
      rules: {
        comBrand:[
          { required: 'true', message: '请选择组件品牌', trigger: ['change'] }
        ],
        comModel: [
          { required: 'true', message: '请选择组件型号', trigger: ['change'] }
        ],
        trueOrFalseRaise: [
          { required: 'true', message: '请选择是否抬高', trigger: ['change'] }
        ],
        installationMethod: [
          { required: 'true', message: '请选择安装方式', trigger: ['change'] }
        ],
        windPressureLevel: [
          { required: 'true', message: '请选择风压等级', trigger: ['change'] }
        ],
        northSouthNumber: [
          { required: 'true', message: '请选择南北排数', trigger: ['change'] }
        ],
        supportType:[
          { required: 'true', message: '请选择支架类型', trigger: ['change'] }
        ],
        installationInclination:[
          { required: 'true', message: '请选择安装倾角', trigger: ['change'] }
        ],
        columnHeight:[
          { required: 'true', message: '请填写立柱高度', trigger: ['change'] }
        ],
        inclinedHeight:[
          { required: 'true', message: '请填写斜梁长度', trigger: ['change'] }
        ],
        inclinedBeamLocation:[
          { required: 'true', message: '请填写斜梁位置', trigger: ['change'] }
        ],
        columnPosition:[
          { required: 'true', message: '请填写立柱位置', trigger: ['change'] }
        ],
        region:[
          { required: 'true', message: '请填写地区', trigger: ['change'] }
        ]
      }
    })
    

    const editState = (s) => {
      state.supportType = s;
    }

    // 开启弹窗
    const open = (data) => {
      state.visible = true;
      console.log(bomRef, atlasRef);
      bomRef.value?.clearFiles();
      atlasRef.value?.clearFiles();
      if (data && data?.id) {
        state.id = data?.id;
        console.log(data);
        state.ruleForm = {
          ...data
        }
      } else {
        state.ruleForm = { ...initialValue }
      }
    }

    // 关闭弹窗
    const close = () => {
      state.visible = false
    }
    const handleClose = () => {
      formRef.value.resetFields();
    }
    const submitForm = () => {
      // if(state.ruleForm.rooftype === 5){
      //   state.ruleForm.trueOrFalseRaise = 0
      // }
      formRef.value.validate((valid) => {
        if (valid) {
          store.dispatch('atlas/addOrUpdate', {
            ...state.ruleForm,
            id: state.id
          }).then(res => {
            if (res.code === 200) {
              handleClose();
              state.visible = false
            }
          });
        } else {
          return false
        }
      })
    }

    const handleAtlasSuccess = (response) => {
      state.ruleForm.atlas = response.data;
    }

    const handleBomSuccess = (response) => {
      state.ruleForm.bom = response.data;
    }

    const handleDownload = fileId => {
      store.dispatch('atlas/download', fileId);
    }

    return {
      ...toRefs(state),
      open,
      close,
      bomRef,
      formRef,
      atlasRef,
      editState,
      submitForm,
      handleClose,
      ROOF_TYPES,
      RAISE_TYPES,
      handleDownload,
      COM_BRAND,
      WIND_AREA,
      PROJECT_TYPE,
      COM_MODAL_TYPE,
      INSTALLATION,
      N_ATLAS,
      SUPPORT_TYPE,
      PULL_BACK_TYPES,
      HOLDER_TYPE,
      NORTH_SOUTH_TYPE,
      handleBomSuccess,
      handleAtlasSuccess,
      INSTALL_METHOD_TYPES,
      WIND_PRESSURE_LEVEL_TYPES,
      loading: computed(() => store?.state?.atlas?.loading),
    }
  }
}
</script>

<style lang="scss" scoped></style>
