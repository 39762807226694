<template>
  <div class="filter">
    <el-form ref="formRef" :model="form" inline>
      <div>
        <el-form-item prop="supportType">
          <el-select v-model="form.supportType" placeholder="请选择支架类型">
            <el-option label="全部" :value="''" />
            <el-option v-for="option in SUPPORT_TYPE" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item prop="comModel">
          <el-select v-model="form.comModel" placeholder="请选择组件型号">
            <el-option label="全部" :value="''" />
            <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label"
              :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item prop="trueOrFalseRaise">
          <el-select v-model="form.trueOrFalseRaise" placeholder="请选择是否抬高">
            <el-option label="全部" :value="0" />
            <el-option v-for="option in RAISE_TYPES" :key="option.value" :label="option.label" :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item prop="installationMethod">
          <el-select v-model="form.installationMethod" placeholder="请选择安装方式">
            <el-option label="全部" :value="0" />
            <el-option v-for="option in INSTALL_METHOD_TYPES" :key="option.value" :label="option.label"
              :value="option.value" />
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item prop="windPressureLevel">
          <el-select v-model="form.windPressureLevel" placeholder="请选择风压等级">
            <el-option label="全部" :value="0" />
            <el-option v-for="option in WIND_PRESSURE_LEVEL_TYPES" :key="option.value" :label="option.label"
              :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item prop="windPressureLevel">
          <el-select v-model="form.comBrand" placeholder="请选择组件品牌">
            <el-option label="全部" :value="''" />
            <el-option v-for="option in COM_BRAND_SEARCH" :key="option.value" :label="option.label"
                       :value="option.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit(formRef)" :loading="loading">查询</el-button>
          <el-button @click="resetForm(formRef)" :loading="loading">重置</el-button>
          <el-button type="primary" @click="handleAdd()">添加图集</el-button>
          <el-button type="primary" @click="handleExport()">导出数据</el-button>
        </el-form-item>
      </div>
    </el-form>
    <Dialog ref="dialogRef" type="add" />
  </div>
</template>

<script setup>
import { useStore  } from 'vuex';
import { ref, computed, reactive } from 'vue';
import Dialog from './dialog.vue';
import { COM_BRAND_SEARCH, SUPPORT_TYPE, COM_MODAL_TYPE, RAISE_TYPES, INSTALL_METHOD_TYPES, WIND_PRESSURE_LEVEL_TYPES } from '../const';
const store = useStore();
const formRef = ref();
const dialogRef = ref(null);
const form = reactive({
  comBrand: '',
  supportType: '',
  comModel: '',
  trueOrFalseRaise: 0,
  windPressureLevel: 0,
  installationMethod: 0
});

store.dispatch('atlas/queryData');

const loading = computed(() => store?.state?.atlas?.loading);

const resetFormValue = () => {
  return {
    comBrand: form.comBrand || '',
    supportType: form.supportType || '',
    comModel: form.comModel,
    trueOrFalseRaise: form.trueOrFalseRaise || 0,
    windPressureLevel: form.windPressureLevel || 0,
    installationMethod: form.installationMethod || 0,
  }
};

const onSubmit = (formEl) => {
  if (!formEl) return;
  store.dispatch('atlas/queryData', {
    page: { page: 1 },
    filterParams: { ...resetFormValue() }
  })
}

const resetForm = (formEl) => {
  form.comBrand = ''
  if (!formEl) return
  formEl.resetFields();
  store.dispatch('atlas/queryData', {
    page: { page: 1 },
    filterParams: { ...resetFormValue()  }
  })
}

const handleAdd = () => {
  dialogRef.value.open();
}

const handleExport = () => {
  store.dispatch('atlas/export', resetFormValue());
}
</script>

<style lang="scss" scoped>
</style>
