<template>
  <div style="height: calc(100% - 144px)">
    <el-table v-loading="loading" :data="dataSource" style="width: 100%" @selection-change="handleSelectionChange"
      table-layout="auto" height="100%">
      <el-table-column type="selection" width="55" />
      <el-table-column property="number" label="编号" />
      <el-table-column label="支架类型">
        <template #default="scope">{{ SUPPORT_TYPE_TEXT[scope.row.supportType] }}</template>
      </el-table-column>
      <el-table-column label="组件品牌">
        <template #default="scope">{{ scope.row.comBrand }}</template>
      </el-table-column>
      <el-table-column label="组件型号">
        <template #default="scope">{{ scope.row.comModel }}</template>
      </el-table-column>
      <el-table-column label="是否抬高">
        <template #default="scope">{{ RAISE_TYPES_TEXT[scope.row.trueOrFalseRaise] }}</template>
      </el-table-column>
      <el-table-column label="安装方式">
        <template #default="scope">{{ INSTALL_METHOD_TYPES_TEXT[scope.row.installationMethod] }}</template>
      </el-table-column>
      <el-table-column label="风压等级">
        <template #default="scope">{{ WIND_PRESSURE_LEVEL_TYPES_TEXT[scope.row.windPressureLevel] }}</template>
      </el-table-column>
      <el-table-column property="northSouthNumber" label="南北排数" />
      <el-table-column label="操作" width="160px" fixed="right">
        <template #default="scope">
          <el-button size="small" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Dialog ref="dialogRef" type="edit" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { ElTable } from 'element-plus';
import { useStore } from 'vuex';
import Dialog from './dialog.vue';
import { SUPPORT_TYPE_TEXT, RAISE_TYPES_TEXT, INSTALL_METHOD_TYPES_TEXT, WIND_PRESSURE_LEVEL_TYPES_TEXT } from '../const';

const dialogRef = ref(null);

const store = useStore();
const multipleSelection = ref([]);

const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const dataSource = computed(() => {
  return store?.state?.atlas?.dataSource;
});

const loading = computed(() => {
  return store?.state?.atlas?.loading;
});

// 编辑
const handleEdit = (data) => {
  dialogRef.value.open(data);
}
// 删除
const handleDelete = (data) => {
  store.dispatch('atlas/delete', data.id);
}
</script>

<style lang="scss" scoped>
</style>
